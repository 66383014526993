import React from 'react'
import Header from '../Header/Header'
import './Contact.css'

function Contact() {
    return (
        <div className="contact">
            <Header />
            <h1>primaverasmusic (at) gmail (dot) com</h1>
        </div>
    )
}

export default Contact
